import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { breakpoints } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  @media ${breakpoints.smallMax} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const messages = css`
  div {
    min-height: ${spacing(60)};
    width: 60%;
    margin-bottom: ${spacing(24)};
    border-radius: ${spacing(8)};

    :nth-of-type(even) {
      align-self: right;
      margin-left: auto;
    }

    :last-of-type {
      margin-bottom: ${spacing(40)};
    }
  }
`;

export const inputs = css`
  padding-top: ${spacing(40)};
  border-top: 1px solid hsl(var(--color-borderBlack-2));
`;

export const textarea = css`
  margin-top: ${spacing(32)};

  @media ${breakpoints.smallMax} {
    display: none;
  }
`;

export const skeletonLabel = css`
  max-width: 40%;
  min-height: ${spacing(20)};
  margin-bottom: ${spacing(4)};
  border-radius: ${spacing(3)};
`;

export const skeleton = css`
  min-height: ${spacing(36)};
  border-radius: ${spacing(3)};
`;

export const skeletonArea = css`
  min-height: ${spacing(136)};
  border-radius: ${spacing(3)};
`;
